import ErrorMessage from '@/components/tx/ErrorMessage'

const NonOwnerError = () => {
  return (
    <ErrorMessage>
      You are currently not an owner of this <span className="notranslate">Safe</span> Account and won&apos;t be
      able to submit this transaction.
    </ErrorMessage>
  )
}

export default NonOwnerError
